$(function() {
 // 以下為Swiper設定

  // 共用Auto 
  var auplayFun = {
    delay: 3500,
    //stopOnLastSlide: false,
    disableOnInteraction: false,
  }

  // 首頁Banner
  if($('.fancy-slider').length > 0) {
    function initSwiper(elem, options) {
      const defaults = {
        speed: 1e3,
        slideScale: 0.6,
        imgScale: 1.2,
        slideScaleDuration: 850,
        imageScaleDuration: 850,
        titleOpacityFadeOutDuration: 850,
        titleOpacityFadeInDuration: 850,
      };
      let params = {...defaults, ...options};
      const speed = params.speed,
        slideScale = params.slideScale,
        imgScale = params.imgScale,
        slideScaleDuration = params.slideScaleDuration,
        imageScaleDuration = params.imageScaleDuration,
        titleOpacityFadeOutDuration = params.titleOpacityFadeOutDuration,
        titleOpacityFadeInDuration = params.titleOpacityFadeInDuration;
      
      const swiper = elem.querySelector(".swiper");
      let activeTransitionEnd = false,
        activeTransitionStart = false,
        animationFrame;
      
      const slideTransition = (elem) => {
          elem.addClass("fancy-slider-no-transition"),
          (activeTransitionStart = true),
          cancelAnimationFrame(animationFrame),
          (animationFrame = requestAnimationFrame(() => {
            elem.removeClass("fancy-slider-no-transition"), (activeTransitionStart = false), (activeTransitionEnd = false);
          }));
        },
        navButtonFn = (elem) => {
          elem.$el.find(".fancy-slider-button-next").on("click", () => {
            activeTransitionEnd || elem.slideNext();
          }),
          elem.$el.find(".fancy-slider-button-prev").on("click", () => {
            activeTransitionEnd || elem.slidePrev();
          });
        },
        disableButton = (elem) => {
          elem.$el
            .find(".fancy-slider-button-next, .fancy-slider-button-prev")
            .off("click");
        };
      return new Swiper(swiper, {
        speed: speed,
        allowTouchMove: false,
        parallax: true,
        loop: false,
        autoplay:true,
        on: {
          transitionStart(elem) {
            const {
                slides: slides,
                previousIndex: prevIndex,
                activeIndex: activeIndex,
                $el: element
              } = elem;
            
            activeTransitionStart || (activeTransitionEnd = true);
            
            const activeSlide = slides.eq(activeIndex),
                  prevSlide = slides.eq(prevIndex),
                  prevSlideScale = prevSlide.find(".fancy-slider-scale"),
                  prevSlideImage = prevSlide.find("img"),
                  activeSlideImage = activeSlide.find("img"),
                  //p = activeIndex - prevIndex,
                  activeSlideBgColor = activeSlide.attr("data-slide-bg-color");
            
            element.css("background-color", activeSlideBgColor),
              prevSlideScale.transition(slideScaleDuration).transform("scale(" + slideScale + ")"),
              prevSlideImage.transition(imageScaleDuration).transform("scale(" + imgScale + ")"),
              prevSlide.find(".fancy-slider-title-text").transition(titleOpacityFadeOutDuration).css("color", "rgba(255,255,255,0)"),
              prevSlide.find(".fancy-slider-title-subtext").transition(titleOpacityFadeOutDuration).css("color", "rgba(255,255,255,0)"),
              prevSlide.find(".fancy-slider-title-head").transition(speed).transform("translateX(-100%)"),
              prevSlideImage.transitionEnd(() => {
                activeSlideImage
                  .transition(speed)
                  .transform("translate3d(0, 0, 0) scale(" + imgScale + ")");
              });
          },
          transitionEnd(elem) {
            const {
                  slides: slides,
                  activeIndex: activeIndex,
                  $el: element
                } = elem,
              activeSlide = slides.eq(activeIndex),
              activeSlideImage = activeSlide.find("img");
    
              activeSlide.find(".fancy-slider-scale").transition(slideScaleDuration).transform("scale(1)"),
              activeSlideImage.transition(imageScaleDuration).transform("scale(1)"),
              activeSlide.find(".fancy-slider-title-text").transition(titleOpacityFadeInDuration).css("color", "rgba(255,255,255,1)").css("-webkit-text-stroke","0px #fff"),
              activeSlide.find(".fancy-slider-title-subtext").transition(titleOpacityFadeInDuration).css("color", "rgba(255,255,255,1)").css("-webkit-text-stroke","0px #fff")
              activeSlide.find(".fancy-slider-title-head").transition(speed).transform("translateX(0%)"),
              activeSlide.find(".fancy-slider-title-img").transition(speed).css('opacity','1'),
              activeSlideImage.transitionEnd(() => {
                  activeTransitionEnd = false;
                }),
              activeIndex === 0
                ? element
                    .find(".fancy-slider-button-prev")
                    .addClass("fancy-slider-button-disabled")
                : element
                    .find(".fancy-slider-button-prev")
                    .removeClass("fancy-slider-button-disabled"),
              activeIndex === slides.length - 1
                ? element
                    .find(".fancy-slider-button-next")
                    .addClass("fancy-slider-button-disabled")
                : element
                    .find(".fancy-slider-button-next")
                    .removeClass("fancy-slider-button-disabled");
          },
          init(elem) {
            const {
              slides: slides,
              activeIndex: activeIndex,
              $el: element
            } = elem;
            
            slideTransition(element);
            const activeSlideBgColor = slides.eq(activeIndex).attr("data-slide-bg-color");
            
            element.css("background-color", activeSlideBgColor),
              elem.emit("transitionEnd"),
              navButtonFn(elem);
          },
          resize(elem) {
            slideTransition(elem.$el);
          },
          destroy(elem) {
            disableButton(elem);
          },
        },
      });
    }
    
    const fancySlider = document.querySelector(".fancy-slider");
    initSwiper(fancySlider, {
      speed: 850, /* swiper speed; slide transition speed */
      slideScale: 0.6, /* after scale out slide - same as in css */
      imgScale: 1.2, /* after scale out img - same as in css */
      slideScaleDuration: 800, /* duration slide scale */
      imageScaleDuration: 750, /* duration image scale */
      titleOpacityFadeOutDuration: 800, /* duration title fade out */
      titleOpacityFadeInDuration: 800, /* duration title fade in */
    });
  }

  // 首頁News
  var gallerSwiper_news = new Swiper('.page-gallerSwiper-news .swiper-container', {
    autoplay: auplayFun,
    // slidesPerView: 3,
    grabCursor : true,
    slideToClickedSlide: true,
    slidesPerView: 'auto',
    slidesPerGroupAuto: true,
    spaceBetween: 50,
    centeredSlides: true,
    loop: true,
    autoHeight: true, //高度随内容变化
    pagination: {
      el: '.page-gallerSwiper-news .swiper-pagination',
      clickable: true,
    },
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 1,
        spaceBetween: 0,
      },
      
      768: {  //当屏幕宽度大于等于768
        slidesPerView: 3,
        spaceBetween: 0,
      },
      
      1000: {  //当屏幕宽度大于等于768
        slidesPerView: 3,
        spaceBetween: 25,
      },
      
      1280: {  //当屏幕宽度大于等于768
        slidesPerView: 3,
        spaceBetween: 0,
      }
    }
  });

  // 首頁Prod
  var gallerSwiper_prod = new Swiper(".page-gallerSwiper-prod .mySwiper", {
    autoplay: auplayFun,
    spaceBetween: 0,
    pagination: {
      el: ".page-gallerSwiper-prod .swiper-pagination",
      clickable: true,
    },
  });

  //鼠标覆盖停止自动切换
gallerSwiper_prod.el.onmouseover = function(){
  gallerSwiper_prod.autoplay.stop();
}

//鼠标离开开始自动切换
gallerSwiper_prod.el.onmouseout = function(){
  gallerSwiper_prod.autoplay.start();
}
  

  // 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });

  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  // 左側分類
  toggleFun('.js-toggle-btn','.js-toggle')

  // 產品側邊選單
  var $slidemenu = $(".link-wrap.pro ul > li > a.first_menu_link");
  $slidemenu.on('click', function () {
    // 點擊當前項目才打開
    var serToggle = $(this).closest('li').find(".first_menu");
    $(".first_menu").not(serToggle).removeClass('open');
    $slidemenu.not($(this)).removeClass('active');

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      serToggle.removeClass('open');
    } else {
      $(this).addClass('active');
      serToggle.addClass('open');
    }

    e.stopPropagation();
  });

  // 產品側邊選單-下一層
  var $slidemenuSecond = $(".first_menu_li > a");
  $slidemenuSecond.on('click',function(){
    // 點擊當前項目才打開
    var serToggleSecond = $(this).closest('li').find(".second_menu");
    $(".second_menu").not(serToggleSecond).removeClass('active');
    $slidemenuSecond.not($(this)).removeClass('active');

    if($(this).hasClass('active')){
      $(this).removeClass('active');
      serToggleSecond.removeClass('active');
    }else{
      $(this).addClass('active');
      serToggleSecond.addClass('active');
    }
  });

  // 
  var $navbarmenu = $(".navbarMobile-toggle > li .nav-mobileBtn");
  $navbarmenu.on('click', function (e) {
    let curNext = $(this).next();
    $('.navbar_first_menu').not(curNext).removeClass('active');
    $(this).next().toggleClass('active');

    e.stopPropagation();
  });
   
  // BS navbar-collapse show / hidden
  $('.navbar-toggler').on('click',function(){
    $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
  })

  $('#js-navbarToggle-fixed').on('click', function () {
    $('#navbar-content').removeClass('show');
    $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
  });

  // input focus & filter
  $("#navbarSearch").on('focus',function() {
    $(".navbar-searchFilter").addClass("open");
  })

  $("#navbarSearch-btn").on('click',function() {
    $(".navbar-searchFilter").addClass("open");
  })

  $('.navbar-searchFilter-close').on('click',function(){
    $(".navbar-searchFilter").removeClass("open");
  })

  // 文字點點點
  checkEllipsis('.index-card-text',50);

  // 打開搜尋bar
  $('.toggle-search').on('click',function() {
    $('.search-formList').toggleClass('open');
    return false;
  });

  $('.btnScroll').on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });

});

function resize() {
  var winHeight = $(window).height();
  $(".modal-autoheight .modal-body").css({
    width: "auto",
    height: (winHeight - 200) + "px"
  });
}

function btnScroll($name){
  $($name).on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });
}

// 取得頁面寬度
function viewport() {
  var e = window,
      a = 'inner';
  if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
  }
  return { width: e[a + 'Width'], height: e[a + 'Height'] };
}

// Toggle Fun
function toggleFun(item,status){
  $(item).on('click',function() {
    $(status).toggleClass('js-open');
    return false;
  });
}

// 文字點點點
function checkEllipsis(name,len){
  $(name).each(function(i){
    if($(this).text().length>len){
        $(this).attr("title",$(this).text());
        let text=$(this).text().substring(0,len-1)+"...";
        $(this).text(text);
    }
  });
}
